import React from "react"
import CountUp from "react-countup"

const CountingNumber = ({ number, decimals, suffix }) => {
	return (
		<CountUp
			duration={2}
			delay={0}
			end={number}
			decimals={decimals}
			suffix={suffix}
		>
			{({ countUpRef }) => <span ref={countUpRef} />}
		</CountUp>
	)
}

export default CountingNumber
